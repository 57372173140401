export default [
  {
    title: 'Tableau de bord',
    route: 'espace-super-admin.dashboard',
    icon: 'HomeIcon',
  },
  {
    title: 'Gestion société',
    icon: 'ListIcon',
    route: 'espace-super-admin.liste.societe',
  },
  {
    title: 'Gestion copropriété',
    route: 'espace-super-admin.liste.copropriete',
    icon: 'ListIcon',
  },
  {
    title: 'Gestion utilisateur',
    route: 'espace-super-admin.liste.utilisateurs',
    icon: 'UsersIcon',
  },
  {
    title: 'Paramètres',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'Catégorie de société',
        route: 'espace-super-admin.liste.categorie.societe',
        color: 'secondary',
        icon: 'DiscIcon',
      },
      {
        title: 'Modèle économique',
        route: 'espace-super-admin.modele.economique',
        color: 'secondary',
        icon: 'DiscIcon',
      },
      {
        title: 'Rôles',
        route: 'espace-super-admin.role',
        color: 'secondary',
        icon: 'DiscIcon',
      },
      {
        title: 'Type de contrat',
        route: 'espace-super-admin.type.contrat',
        color: 'secondary',
        icon: 'DiscIcon',
      },
      // {
      //   title: 'Catégories de frais',
      //   route: 'espace-super-admin.categorie.frais',
      //   color: 'secondary',
      //   icon: 'DiscIcon',
      // },

    ],
  },
]
